<!-- modal -->
<div class="fixed z-50 inset-0 overscroll-y-contain overflow-y-auto">
  <div class="relative flex flex-col justify-end min-h-full xl:justify-start xl:py-20">
    <div class="absolute inset-0 bg-gray-1100 bg-opacity-20" role="button" (click)="closeHandler()"></div>
    <div class="relative w-full p-10 bg-white rounded-t-2xl xl:max-w-2xl xl:mx-auto xl:rounded-2xl xl:shadow-xl">
      <p class="font-medium text-2xl text-gray-1100">{{ title }}</p>
      <div class="mt-10 space-y-4">
        <button class="button button-solid-green button-lg w-full" type="button" (click)="confirmHandler()">{{ confirmLabel }}</button>
        <button class="button button-outline button-lg w-full" type="button" (click)="cancelHandler()">{{ cancelLabel }}</button>
      </div>
    </div>
  </div>
</div>
