// Angular
import { Component, HostListener, Input, TemplateRef } from '@angular/core';
// Models
import { Overlay } from '../../models';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {

  @Input()
  public title!: string;

  @Input()
  public templateRef!: TemplateRef<HTMLElement>;

  constructor(private overlay: Overlay<ModalComponent>) {
  }

  @HostListener('window:popstate')
  @HostListener('window:keydown.escape')
  public closeHandler(): void {
    this.overlay.dismiss();
  }
}
