// Angular
import { Directive, Input, TemplateRef } from '@angular/core';
// Components
import { ModalComponent } from '../../components/modal';
// Models
import { Overlay } from '../../models';
// Services
import { OverlayService } from '../../services/overlay.service';

@Directive({
  selector: '[modalTemplate]',
  exportAs: 'modalTemplate'
})
export class ModalTemplateDirective {

  @Input('modalTemplate')
  public title!: string;

  public modal: Overlay<ModalComponent> | null;

  constructor(private templateRef: TemplateRef<HTMLElement>, private overlayService: OverlayService) {
    this.modal = null;
  }

  public present(): void {
    this.modal = this.overlayService.createFromTemplate(this.templateRef);
    this.modal.instance.title = this.title;
    this.modal.onDismiss.subscribe({
      next: () => {
        this.modal = null;
      }
    });
    this.modal.present();
  }

  public dismiss(data?: any): void {
    if (!!this.modal) {
      this.modal.dismiss(data);
    }
  }
}
