// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Components
import { ConfirmComponent } from './components/confirm';
import { ModalComponent } from './components/modal';
import { NotificationComponent } from './components/notification';
import { NotificationsComponent } from './components/notifications';
import { PaginationComponent } from './components/pagination';
import { TooltipComponent } from './components/tooltip';
// Directives
import { BodyScrollDirective } from './directives/body-scroll';
import { DropdownDirective } from './directives/dropdown';
import { ModalTemplateDirective } from './directives/modal-template';
import { PreventDefaultDirective } from './directives/prevent-default';
import { StopPropagationDirective } from './directives/stop-propagation';
import { ToggleDirective } from './directives/toggle';
// Pipes
import { BytesPipe } from './pipes/bytes';
import { SafeHtmlPipe } from './pipes/safe-html';
// Services
import { ConfirmService } from './services/confirm.service';
import { CookiesService } from './services/cookies.service';
import { NotificationService } from './services/notification.service';
import { OverlayService } from './services/overlay.service';
import { FormModule } from '@libs/form/form.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FormModule
  ],
  declarations: [
    ConfirmComponent,
    ModalComponent,
    NotificationComponent,
    NotificationsComponent,
    PaginationComponent,
    TooltipComponent,
    BodyScrollDirective,
    DropdownDirective,
    ModalTemplateDirective,
    PreventDefaultDirective,
    StopPropagationDirective,
    ToggleDirective,
    BytesPipe,
    SafeHtmlPipe
  ],
  exports: [
    ConfirmComponent,
    ModalComponent,
    NotificationComponent,
    NotificationsComponent,
    PaginationComponent,
    TooltipComponent,
    BodyScrollDirective,
    DropdownDirective,
    ModalTemplateDirective,
    PreventDefaultDirective,
    StopPropagationDirective,
    ToggleDirective,
    BytesPipe,
    SafeHtmlPipe
  ]
})
export class CoreModule {

  constructor() {
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ConfirmService,
        CookiesService,
        NotificationService,
        OverlayService
      ]
    };
  }
}
