<div class="inline-flex gap-2">
  <button class="button button-outline relative z-10 w-10 h-10 p-0" aria-label="previous" [disabled]="page === 1" type="button" (click)="previousPage()"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M3.3335 8.00007L13.3335 7.99987" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.99995 4.66667L3.13802 7.5286C2.91579 7.7508 2.80469 7.86193 2.80469 8C2.80469 8.13807 2.91579 8.2492 3.13802 8.4714L5.99995 11.3333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
  <div class="relative">
    <p class="flex items-center justify-center gap-1 h-10 py-2 px-4 font-medium text-sm text-center text-gray-1100 border border-gray-400 rounded-lg shadow"><span class="text-gray-700">Strona</span><span>{{ page }} z {{ pageCount }}</span><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L7.5286 9.5286C7.7508 9.7508 7.86193 9.86193 8 9.86193C8.13807 9.86193 8.2492 9.7508 8.4714 9.5286L12 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></p>
    <select class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" [ngModel]="page" (ngModelChange)="selectPage($event)" aria-label="page" tabindex="-1">
      <option value="" selected disabled>Wybierz stronę</option>
      <option *ngFor="let index of allPages" [ngValue]="index">{{ index }}</option>
    </select>
  </div>
  <button class="button button-outline relative z-10 w-10 h-10 p-0" aria-label="next" [disabled]="page === pageCount" type="button" (click)="nextPage()"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M12.6665 8H2.6665" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 4.66667L12.8619 7.5286C13.0841 7.7508 13.1953 7.86193 13.1953 8C13.1953 8.13807 13.0841 8.2492 12.8619 8.4714L10 11.3333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
</div>
