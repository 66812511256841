// Angular
import { Injectable } from '@angular/core';
// Components
import { NotificationsComponent } from '../components/notifications';
// Models
import { NotificationModel, Overlay } from '../models';
// Services
import { OverlayService } from './overlay.service';

@Injectable()
export class NotificationService {

  public notifications: Array<NotificationModel>;

  private overlay: Overlay<NotificationsComponent> | null;

  constructor(private overlayService: OverlayService) {
    this.notifications = [];
    this.overlay = null;
  }

  private addNotification(notification: NotificationModel): void {
    this.notifications.push(notification);

    if (!this.overlay) {
      this.overlay = this.overlayService.create(NotificationsComponent);
      this.overlay.instance.notifications = this.notifications;
      this.overlay.onDismiss.subscribe({
        next: () => {
          this.overlay = null;
        }
      });
      this.overlay.present();
    }
  }

  public showSuccess(message: string, duration: number = 3500): void {
    this.addNotification({ type: 'success', icon: 'success', message, canClose: true, duration });
  }

  public showInfo(message: string, duration: number = 3500): void {
    this.addNotification({ type: 'info', icon: 'info', message, canClose: true, duration });
  }

  public showWarning(message: string, duration: number = 3500): void {
    this.addNotification({ type: 'warning', icon: 'warning', message, canClose: true, duration });
  }

  public showDanger(message: string, duration: number = 3500): void {
    this.addNotification({ type: 'danger', icon: 'danger', message, canClose: true, duration });
  }
}
