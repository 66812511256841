// Angular
import { Injectable } from '@angular/core';

@Injectable()
export class CookiesService {

  constructor() {
  }

  public static getCookie(name: string): string {
    const cookies = document.cookie.split(';');
    const cookie = cookies.find(cookie => cookie.includes(`${name}=`));

    return cookie || '';
  }

  public static changeCookie(name: string, value: string): void {
    const cookies = document.cookie.split(';');

    let newCookieString = '';

    for (const cookie of cookies) {
      const cookieParts = cookie.split('=');
      const cookieName = cookieParts[0].trim();
      if (cookieName === name) {
        newCookieString += `${name}=${value}; ${cookie.split(';').slice(1).join('; ')};`;
      } else {
        newCookieString += `${cookie.trim()}; `;
      }
    }

    document.cookie = newCookieString.slice(0, -2);
  }
}
