// Angular
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// Models
import { StaffModel } from '@data/models';
// Services
import { AuthService } from '@data/services/auditor/auth.service';
import { StaffService } from '@data/services/auditor/staff.service';
// External
import { BehaviorSubject, map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuditorGuard {

  public staffMember: BehaviorSubject<StaffModel | null>;

  constructor(private router: Router, private authService: AuthService, private staffService: StaffService) {
    this.staffMember = new BehaviorSubject<StaffModel | null>(null);

    const auth = JSON.parse(window.localStorage.getItem('auth') || '""') || null;
    this.authService.auth.next(auth);
  }

  public canActivate(state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.auth.value || !this.authService.auth.value.staffId) {
      return state.url === '/auth/login' || this.router.parseUrl(`/auth/login?redirectUrl=${encodeURIComponent(state.url)}`);
    }

    if (!this.staffMember.value) {
      return this.staffService.getStaffMember(this.authService.auth.value.staffId).pipe(
        map(staffMember => {
          this.staffMember.next(staffMember);
          return true;
        }),
        catchError(error => {
          window.localStorage.removeItem('auth');
          this.authService.auth.next(null);

          return throwError(() => error).pipe(
            map(() => state.url === '/auth/login' || this.router.parseUrl(`/auth/login?redirectUrl=${encodeURIComponent(state.url)}`))
          );
        })
      );
    }

    return true;
  }
}
