export type StaffRoleType = 'ROLE_ADMIN' | 'ROLE_AUDITOR' | 'ROLE_CONSULTANT' | string;

export const StaffRoleLabels: { [key: StaffRoleType]: string; } = {
  'ROLE_ADMIN': 'Administrator',
  'ROLE_AUDITOR': 'Audytor',
  'ROLE_CONSULTANT': 'Konsultant'
};

export interface StaffModel {
  id: string;
  role: StaffRoleType;
  firstName: string;
  lastName: string;
  email: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}
