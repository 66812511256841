// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { StaffModel, PaginationModel, ApiDataModel } from '../../models';
// Services
import { HelperService } from '../helper.service';
// External
import { Observable } from 'rxjs';

@Injectable()
export class StaffService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getStaffMembers(filter: ApiDataModel): Observable<PaginationModel<StaffModel>> {
    const params = HelperService.GetHttpParams(filter);
    return this.httpClient.get<PaginationModel<StaffModel>>(`${this.apiUrl}/app-auditor/v1/staff`, { params });
  }

  public createStaffMember(data: ApiDataModel): Observable<StaffModel> {
    return this.httpClient.post<StaffModel>(`${this.apiUrl}/app-auditor/v1/staff`, data);
  }

  public getStaffMember(staffId: string): Observable<StaffModel> {
    return this.httpClient.get<StaffModel>(`${this.apiUrl}/app-auditor/v1/staff/${staffId}`);
  }

  public editStaffMember(staffId: string, data: ApiDataModel): Observable<StaffModel> {
    return this.httpClient.post<StaffModel>(`${this.apiUrl}/app-auditor/v1/staff/${staffId}`, data);
  }

  public activateStaffMember(staffId: string): Observable<StaffModel> {
    return this.httpClient.post<StaffModel>(`${this.apiUrl}/app-auditor/v1/staff/${staffId}/activate`, null);
  }

  public deactivateStaffMember(staffId: string): Observable<StaffModel> {
    return this.httpClient.post<StaffModel>(`${this.apiUrl}/app-auditor/v1/staff/${staffId}/deactivate`, null);
  }

  public changeStaffMemberPassword(staffId: string, newPassword: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-auditor/v1/staff/${staffId}/password`, { newPassword });
  }

  public deleteStaffMember(staffId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/staff/${staffId}`);
  }

  public requestResetPassword(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-auditor/v1/staff/requestResetPassword`, { email });
  }

  public resetPassword(resetToken: string, password: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-auditor/v1/staff/resetPassword/${resetToken}`, { password });
  }
}
