export const GmpScopeNames = [
  { name: 'GMP+2020 Transport drogowy pasz', shortName: 'Transport' },
  { name: 'GMP+2020 Handel materiałami paszowymi', shortName: 'Handel' },
  { name: 'GMP+2020 Magazynowanie i handel materiałami paszowymi', shortName: 'Magazynowanie i handel' },
  { name: 'GMP+2020 Produkcja i handel materiałami paszowymi', shortName: 'Produkcja i handel' },
  { name: 'GMP+2020 Produkcja, magazynowanie i handel materiałami paszowymi', shortName: 'Produkcja, magazynowanie i handel' }
];

export interface GmpModel {
  companyGmpStaffNumber: number;
  companyTotalStaffNumber: number;
  certificationBody: string;
  haccpTeamMeetingDate: string;
  haccpTeamMeetingTopic: string;
  haccpVerificationProtocolDate: string;
  suppliersAssessmentDate: string;
  recallSimulationDate: string;
  recallSimulationProduct: string;
}
