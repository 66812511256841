// Angular
import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
// Guards
import { AuditorGuard } from './guards/auditor.guard';
// Layouts
import { BaseLayoutComponent } from './layouts/base-layout';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuditorGuard).canActivate(state)],
    component: BaseLayoutComponent,
    children: [
      { path: 'accounts', loadChildren: () => import('./modules/staff/staff.module').then(m => m.StaffModule) },
      { path: 'companies', loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule) },
      { path: 'procedures', loadChildren: () => import('./modules/procedure/procedure.module').then(m => m.ProcedureModule) },
      { path: '', redirectTo: 'companies', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '/companies' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

  constructor() {
  }
}
