// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Services
import { FileService } from '../../services/auditor/file.service';
// External
import { Observable } from 'rxjs';

@Pipe({
  name: 'fileAsync'
})
export class FileAsyncPipe implements PipeTransform {

  constructor(private fileService: FileService) {
  }

  public transform(fileId: string): Observable<{ url: string; }> {
    return this.fileService.downloadFile(fileId);
  }
}
