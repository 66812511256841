// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { ApiDataModel, ReportDataModel } from '../../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class ReportDataService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getReportData(companyId: string): Observable<ReportDataModel> {
    return this.httpClient.get<ReportDataModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/year/2024/reportData`);
  }

  public editReportData(companyId: string, data: ApiDataModel): Observable<ReportDataModel> {
    return this.httpClient.post<ReportDataModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/year/2024/reportData`, data);
  }
}
