export function isSameDate(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isToday(year: number, month: number, date: number): boolean {
  return isSameDate(new Date(year, month, date), new Date());
}

export function diffDays(date1: Date, date2: Date = new Date()): number {
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  const beforeAfter = utc2 - utc1 < 0 ? -1 : 1;

  return Math.ceil(Math.abs((utc2 - utc1) / 1000 / 60 / 60 / 24)) * beforeAfter;
}

export function diffMonths(date1: Date, date2: Date = new Date()): number {
  return date2.getMonth() - date1.getMonth() + (12 * (date2.getFullYear() - date1.getFullYear()));
}

export function getDateFromString(dateString: string): Date {
  const [date, month, year] = dateString.split('.');
  return new Date(+year, +month - 1, +date);
}

export function getWeekDays(year: number, month: number, date: number): Array<Date> {
  const weekDays = [];

  const firstWeekDay = new Date(year, month, date);

  if (firstWeekDay.getDay() !== 1) {
    firstWeekDay.setDate(firstWeekDay.getDate() - firstWeekDay.getDay() + 1);
  }

  for (let i = 0; i < 7; i++) {
    weekDays.push(new Date(year, month, firstWeekDay.getDate() + i));
  }

  return weekDays;
}

export function getMonthDays(year: number, month: number, withOffset: boolean = false): Array<Date> {
  const monthDays = [];

  const firstMonthDay = new Date(year, month);
  const lastMonthDay = new Date(year, month + 1, 0);

  const startOffsetIndex = firstMonthDay.getDay() - 1;
  const startOffsetDays = startOffsetIndex < 0 ? 7 + startOffsetIndex : startOffsetIndex;

  const endOffsetIndex = 7 - lastMonthDay.getDay();
  const endOffsetDays = endOffsetIndex === 7 ? 0 : endOffsetIndex;

  if (withOffset) {
    for (let i = 0; i < startOffsetDays; i++) {
      monthDays.push(new Date(year, month, -startOffsetDays + i + 1));
    }
  }

  for (let i = 1; i <= lastMonthDay.getDate(); i++) {
    monthDays.push(new Date(year, month, i));
  }

  if (withOffset && !!endOffsetDays) {
    for (let i = 0; i < endOffsetDays; i++) {
      monthDays.push(new Date(year, month + 1, i + 1));
    }
  }

  return monthDays;
}

export interface CalendarCard {
  year: number;
  month: number;
  days: Array<number>;
  dayNames: Array<string>;
  dayOffset: Array<number>;
}
