// Angular
import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[disableBodyScroll]'
})
export class BodyScrollDirective implements OnInit, OnDestroy {

  constructor(private elementRef: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('touchstart', this.stopPropagation);
    this.elementRef.nativeElement.addEventListener('touchmove', this.stopPropagation);
    this.elementRef.nativeElement.addEventListener('touchend', this.stopPropagation);
    this.elementRef.nativeElement.addEventListener('wheel', this.wheelHandler.bind(this));
    this.elementRef.nativeElement.addEventListener('keydown', this.stopPropagation);

    document.body.addEventListener('touchstart', this.preventDefault, { passive: false });
    document.body.addEventListener('touchmove', this.preventDefault, { passive: false });
    document.body.addEventListener('touchend', this.preventDefault, { passive: false });
    document.body.addEventListener('wheel', this.preventDefault, { passive: false });
    document.body.addEventListener('keydown', this.preventDefault, { passive: false });
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('touchstart', this.stopPropagation);
    this.elementRef.nativeElement.removeEventListener('touchmove', this.stopPropagation);
    this.elementRef.nativeElement.removeEventListener('touchend', this.stopPropagation);
    this.elementRef.nativeElement.removeEventListener('wheel', this.wheelHandler);
    this.elementRef.nativeElement.removeEventListener('keydown', this.stopPropagation);

    document.body.removeEventListener('touchstart', this.preventDefault);
    document.body.removeEventListener('touchmove', this.preventDefault);
    document.body.removeEventListener('touchend', this.preventDefault);
    document.body.removeEventListener('wheel', this.preventDefault);
    document.body.removeEventListener('keydown', this.preventDefault);
  }

  private preventDefault(event: Event): void {
    event.preventDefault();
  }

  private stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  private wheelHandler(event: WheelEvent): void {
    const targetIsChild = this.elementRef.nativeElement.contains(event.target as HTMLElement);
    const { offsetHeight, scrollHeight } = this.elementRef.nativeElement;

    if (targetIsChild && offsetHeight === scrollHeight) {
      event.preventDefault();
    }

    event.stopPropagation();
  }
}
