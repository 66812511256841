<!-- modal -->
<div class="fixed z-50 inset-0 overflow-y-auto overscroll-y-contain">
  <div class="flex flex-col items-center justify-center relative min-h-full xl:py-20">
    <div class="absolute inset-0 bg-white xl:bg-gray-1100 xl:bg-opacity-10 xl:backdrop-blur-sm" role="button" (click)="closeHandler()"></div>
    <div class="relative w-full p-10 bg-white xl:max-w-2xl xl:mx-auto xl:rounded-2xl xl:shadow-xl">
      <p class="font-medium text-2xl text-gray-1100">{{ title }}</p>
      <div class="mt-6">
        <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
      </div>
    </div>
  </div>
</div>
