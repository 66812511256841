// Angular
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent {

  @Input()
  public title!: string;

  @Input()
  public confirm!: Function;

  @Input()
  public confirmLabel: string;

  @Input()
  public cancelLabel: string;

  @Output()
  public close: EventEmitter<void>;

  constructor() {
    this.confirmLabel = 'OK';
    this.cancelLabel = 'Anuluj';

    this.close = new EventEmitter();
  }

  public confirmHandler(): void {
    this.closeHandler();
    this.confirm();
  }

  public cancelHandler(): void {
    this.closeHandler();
  }

  @HostListener('window:popstate')
  @HostListener('window:keydown.escape')
  public closeHandler(): void {
    this.close.emit();
  }
}
