// Angular
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventDefault]'
})
export class PreventDefaultDirective {

  constructor() {
  }

  @HostListener('click', ['$event'])
  public clickHandler(event: MouseEvent) {
    event.preventDefault();
  }
}
