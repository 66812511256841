// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import {
  ApiDataModel,
  BranchModel,
  CompanyAccountModel,
  CompanyModel,
  CompanyProcedureModel,
  PaginationModel
} from '../../models';
// Services
import { HelperService } from '../helper.service';
// External
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getCompanies(filter: ApiDataModel): Observable<PaginationModel<CompanyModel>> {
    const params = HelperService.GetHttpParams(filter);
    return this.httpClient.get<PaginationModel<CompanyModel>>(`${this.apiUrl}/app-auditor/v1/companies`, { params });
  }

  public createCompany(data: ApiDataModel): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies`, data);
  }

  public getCompany(companyId: string): Observable<CompanyModel> {
    return this.httpClient.get<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}`);
  }

  public assignCompanyToStaff(companyId: string, staff: string | null): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/assignTo`, { staff });
  }

  public editCompany(companyId: string, data: ApiDataModel): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}`, data);
  }

  public editCompanyAdditionalSection(companyId: string, data: ApiDataModel): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/additional`, data);
  }

  public editCompanyTraceabilitySection(companyId: string, data: ApiDataModel): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/traceability`, data);
  }

  public editCompanyVeterinarySection(companyId: string, data: ApiDataModel): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/veterinary`, data);
  }

  // company account
  public getCompanyAccounts(companyId: string, filter: ApiDataModel): Observable<PaginationModel<CompanyAccountModel>> {
    const params = HelperService.GetHttpParams(filter);
    return this.httpClient.get<PaginationModel<CompanyAccountModel>>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/companyAccounts`, { params });
  }

  public createCompanyAccount(companyId: string, data: ApiDataModel): Observable<CompanyAccountModel> {
    return this.httpClient.post<CompanyAccountModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/companyAccounts`, data);
  }

  public getCompanyAccount(companyAccountId: string): Observable<CompanyAccountModel> {
    return this.httpClient.get<CompanyAccountModel>(`${this.apiUrl}/app-auditor/v1/companyAccounts/${companyAccountId}`);
  }

  public changeCompanyAccountPassword(companyAccountId: string, newPassword: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-auditor/v1/companyAccounts/${companyAccountId}/password`, { newPassword });
  }

  public activateCompanyAccount(companyAccountId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-auditor/v1/companyAccounts/${companyAccountId}/activate`, null);
  }

  public deactivateCompanyAccount(companyAccountId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-auditor/v1/companyAccounts/${companyAccountId}/deactivate`, null);
  }

  public deleteCompanyAccount(companyAccountId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/companyAccounts/${companyAccountId}`);
  }

  // company branch
  public createBranch(companyId: string, data: ApiDataModel): Observable<BranchModel> {
    return this.httpClient.post<BranchModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/branches`, data);
  }

  public getBranch(branchId: string): Observable<BranchModel> {
    return this.httpClient.get<BranchModel>(`${this.apiUrl}/app-auditor/v1/branches/${branchId}`);
  }

  public editBranch(branchId: string, data: ApiDataModel): Observable<BranchModel> {
    return this.httpClient.post<BranchModel>(`${this.apiUrl}/app-auditor/v1/branches/${branchId}`, data);
  }

  public deleteBranch(branchId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/branches/${branchId}`);
  }

  // company license
  public activateLicense(companyId: string, licenseValidTo: string): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/license/activate`, { licenseValidTo });
  }

  public deactivateLicense(companyId: string): Observable<CompanyModel> {
    return this.httpClient.post<CompanyModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/license/deactivate`, null);
  }

  // company procedure
  public getProcedures(companyId: string, year: number): Observable<Array<CompanyProcedureModel>> {
    return this.httpClient.get<Array<CompanyProcedureModel>>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/procedures/${year}`);
  }

  public getProcedure(companyProcedureId: string): Observable<CompanyProcedureModel> {
    return this.httpClient.get<CompanyProcedureModel>(`${this.apiUrl}/app-auditor/v1/companyProcedures/${companyProcedureId}`);
  }

  public addProcedure(companyId: string, year: number, procedure: string): Observable<CompanyProcedureModel> {
    return this.httpClient.post<CompanyProcedureModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/procedures/${year}/add`, { procedure });
  }

  public archiveProcedures(companyId: string, year: number, procedures: Array<string>): Observable<Array<CompanyProcedureModel>> {
    return this.httpClient.post<Array<CompanyProcedureModel>>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/procedures/${year}/archive`, { procedures });
  }

  public editProcedure(companyProcedureId: string, year: number, code: string, data: ApiDataModel): Observable<CompanyProcedureModel> {
    return this.httpClient.post<CompanyProcedureModel>(`${this.apiUrl}/app-auditor/v1/companyProcedures/${companyProcedureId}/${year}/${code}`, data);
  }

  public renderCompanyProcedureHTML(companyProcedureId: string): Observable<string> {
    return this.httpClient.get(`${this.apiUrl}/app-auditor/v1/companyProcedures/${companyProcedureId}/render/html`, { responseType: 'text' });
  }

  public renderCompanyProcedurePDF(companyProcedureId: string): Observable<{ url: string; }> {
    return this.httpClient.get<{ url: string; }>(`${this.apiUrl}/app-auditor/v1/companyProcedures/${companyProcedureId}/render/pdf`);
  }
}
