// Angular
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: 'input[passwordStrength][ngModel], input[passwordStrength][formControl], input[passwordStrength][formControlName]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PasswordStrengthDirective, multi: true }
  ]
})
export class PasswordStrengthDirective implements Validator {

  constructor() {
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }

    const value = control.value.toString();
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

    return regex.test(value) ? null : { passwordStrength: true };
  }
}
