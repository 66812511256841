// Angular
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
// Services
import { AuthService } from '@data/services/auditor/auth.service';
// External
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuditorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.authorizationHandler(request, next).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.error.message === 'Expired JWT Token') {
          return this.authService.refresh().pipe(
            switchMap(auth => {
              window.localStorage.setItem('auth', JSON.stringify(auth));
              this.authService.auth.next(auth);

              return this.authService.authorizationHandler(request, next);
            })
          );
        }

        if (error.status === 401 && !request.url.includes('jwt')) {
          window.localStorage.removeItem('auth');
          this.authService.auth.next(null);

          return from(this.router.navigate(['auth', 'login'])).pipe(
            switchMap(() => throwError(() => error))
          );
        }

        if (error.status === 503) {
          window.location.reload();
        }

        return throwError(() => error);
      })
    );
  }
}
