// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { ProcedureModel, ProcedureVersionModel } from '../../models';
// Services
import { HelperService } from '../helper.service';
// External
import { Observable } from 'rxjs';

@Injectable()
export class ProcedureService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getProcedures(): Observable<Array<ProcedureModel>> {
    return this.httpClient.get<Array<ProcedureModel>>(`${this.apiUrl}/app-auditor/v1/procedures`);
  }

  public getProcedure(procedureId: string): Observable<ProcedureModel> {
    return this.httpClient.get<ProcedureModel>(`${this.apiUrl}/app-auditor/v1/procedures/${procedureId}`);
  }

  // procedure version
  public getProcedureVersions(procedureId: string): Observable<Array<ProcedureVersionModel>> {
    return this.httpClient.get<Array<ProcedureVersionModel>>(`${this.apiUrl}/app-auditor/v1/procedures/${procedureId}/versions`);
  }

  public createProcedureVersionDraft(procedureId: string, fromProcedureVersion?: string): Observable<ProcedureVersionModel> {
    const params = !!fromProcedureVersion ? HelperService.GetHttpParams({ fromProcedureVersion }) : {};
    return this.httpClient.post<ProcedureVersionModel>(`${this.apiUrl}/app-auditor/v1/procedures/${procedureId}/versions`, null, { params });
  }

  public getProcedureVersion(procedureVersionId: string): Observable<ProcedureVersionModel> {
    return this.httpClient.get<ProcedureVersionModel>(`${this.apiUrl}/app-auditor/v1/procedureVersions/${procedureVersionId}`);
  }

  public editProcedureVersion(procedureVersionId: string, template: string): Observable<ProcedureVersionModel> {
    return this.httpClient.post<ProcedureVersionModel>(`${this.apiUrl}/app-auditor/v1/procedureVersions/${procedureVersionId}`, { template });
  }

  public deleteProcedureVersion(procedureVersionId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/procedureVersions/${procedureVersionId}`);
  }

  public getProcedureVersionPdf(procedureVersionId: string): Observable<{ url: string; }> {
    return this.httpClient.get<{ url: string; }>(`${this.apiUrl}/app-auditor/v1/procedureVersions/${procedureVersionId}/pdf`);
  }

  public getProcedureVersionTemplate(procedureVersionId: string): Observable<string> {
    return this.httpClient.get(`${this.apiUrl}/app-auditor/v1/procedureVersions/${procedureVersionId}/template`, { responseType: 'text' });
  }

  public publishProcedureVersion(procedureVersionId: string): Observable<ProcedureVersionModel> {
    return this.httpClient.post<ProcedureVersionModel>(`${this.apiUrl}/app-auditor/v1/procedureVersions/${procedureVersionId}/publish`, null);
  }
}
