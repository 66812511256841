// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { FileModel } from '@data/models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class FileService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getFile(fileId: string): Observable<FileModel> {
    return this.httpClient.get<FileModel>(`${this.apiUrl}/app-auditor/v1/files/${fileId}`);
  }

  public deleteFile(fileId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/files/${fileId}`);
  }

  public downloadFile(fileId: string): Observable<{ url: string; }> {
    return this.httpClient.get<{ url: string; }>(`${this.apiUrl}/app-auditor/v1/files/${fileId}/download`);
  }

  public uploadFile(file: File): Observable<FileModel> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<FileModel>(`${this.apiUrl}/app-auditor/v1/files`, formData);
  }
}
