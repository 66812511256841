// Angular
import { Injectable } from '@angular/core';
// Components
import { ConfirmComponent } from '../components/confirm';
// Services
import { OverlayService } from './overlay.service';

@Injectable()
export class ConfirmService {

  constructor(private overlayService: OverlayService) {
  }

  public showConfirm(title: string, confirm: Function, confirmLabel: string = 'OK', cancelLabel: string = 'Anuluj'): void {
    const overlay = this.overlayService.create(ConfirmComponent);
    overlay.instance.title = title;
    overlay.instance.confirm = confirm;
    overlay.instance.confirmLabel = confirmLabel;
    overlay.instance.cancelLabel = cancelLabel;
    overlay.instance.close.subscribe({
      next: () => {
        overlay.dismiss();
      }
    });
    overlay.present();
  }
}
