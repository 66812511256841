// Models
import { BranchModel } from './branch.model';
import { ProcedureModel, ProcedureVersionModel } from './procedure.model';
import { StaffModel } from './staff.model';
import { TransportMeanModel } from './transport-mean.model';

export const CompanyProcedureExcludedForm = ['P00', 'P00.3', 'P01', 'P01.1', 'P01.2', 'P02', 'P03', 'P04', 'P05', 'P06', 'P07', 'P08', 'P08.1', 'P08.3'];

export interface CompanyAccountModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: CompanyModel;
  passwordChangeRequired: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CompanyAdditionalGmpScopeModel {
  name: string;
  shortName: string;
  description: string;
}

export interface CompanyAdditionalHazardAnalysisStepModel {
  step: number;
  name: string;
  description: string;
}

export interface CompanyProcedureModel {
  id: string;
  companyId: string;
  archived: boolean;
  procedure: ProcedureModel;
  procedureVersion: ProcedureVersionModel;
  year: number;
  data: any;
  createdAt: string;
  updatedAt: string;
}

export interface CompanySectionAdditionalModel {
  phoneNumber: string;
  contactPerson: string;
  contactPersonPhoneNumber: string;
  companyEstablishedYear: number;
  transportVolume: string;
  mainActivity: string;
  mainProducts: string;
  gmpNumber: string;
  gmpScopes: Array<CompanyAdditionalGmpScopeModel>;
  documentsStorageTime: string;
  haccpTeamEstablishedDate: string;
  haccpTeamDocument: string;
  haccpValidationTeamEstablishedDate: string;
  haccpManagementRepAppointmentDate: string;
  haccpManagementRepDocument: string;
  haccpManagementRepName: string;
  haccpManagementRepRole: string;
  haccpImplementedByName: string;
  haccpImplementedByRole: string;
  haccpTeamMembers: Array<{ name: string; role: string; }>;
  haccpValidationTeamMembers: Array<{ name: string; }>;
  hazardAnalysisSteps: Array<CompanyAdditionalHazardAnalysisStepModel>;
}

export interface CompanySectionVeterinaryModel {
  inspectorateCity: string;
  veterinaryNumber: string;
  scopes: Array<{ scope: string; }>;
  inspectionDate: string;
  inspectionNumber: string;
  inspectionRemarks: string;
}

export interface CompanySectionTraceabilityModel {
  documentCmrSender: string;
  documentCmrConsignee: string;
  documentCmrShippingDate: string;
  documentCmrDeliveryDate: string;
  documentCmrCargoType: string;
  documentCmrCargoWeight: string;
  documentCmrPlateNumber: string;
  documentPzNumber: string;
  documentPzDate: string;
  documentPzIssuer: string;
  documentPzCargoType: string;
  documentPzCargoWeight: string;
  documentPzPlateNumber: string;
  documentWzNumber: string;
  documentWzDate: string;
  documentWzIssuer: string;
  documentWzCargoType: string;
  documentWzCargoWeight: string;
  documentWzPlateNumber: string;
  documentPurchaseInvoiceNumber: string;
  documentPurchaseInvoiceDate: string;
  documentPurchaseInvoiceSeller: string;
  documentPurchaseInvoiceBuyer: string;
  documentPurchaseInvoiceTitle: string;
  documentSalesInvoiceNumber: string;
  documentSalesInvoiceDate: string;
  documentSalesInvoiceSeller: string;
  documentSalesInvoiceBuyer: string;
  documentSalesInvoiceTitle: string;
  documentContractNumber: string;
  documentContractDate: string;
  documentContractBuyer: string;
  documentContractSeller: string;
  documentContractTitle: string;
  documentWeightBillNumber: string;
  documentWeightBillDate: string;
  documentWeightBillIssuer: string;
  documentWeightBillCargoType: string;
  documentWeightBillCargoWeight: string;
  documentWeightBillPlateNumber: string;
  documentQualityCertificateLaboratory: string;
  documentQualityCertificateAccreditation: string;
  documentQualityCertificateNumber: string;
  documentQualityCertificateDate: string;
  documentQualityCertificateTitle: string;
  documentTrailerLogbookPlateNumber: string;
  documentTrailerLogbookLoadingPlace: string;
  documentTrailerLogbookLoadingDate: string;
  documentTrailerLogbookUnloadingPlace: string;
  documentTrailerLogbookUnloadingDate: string;
  documentTrailerLogbookCargoWeight: string;
  documentTrailerLogbookCargoType: string;
  documentTrailerLogbookCleaning: string;
}

export interface CompanyModel {
  id: string;
  name: string;
  shortName: string;
  vatId: string;
  email: string;
  phoneNumber: string;
  branches: Array<BranchModel>;
  addedBy: StaffModel;
  assignedTo: StaffModel | null;
  sectionAdditional: CompanySectionAdditionalModel;
  sectionVeterinary: CompanySectionVeterinaryModel;
  sectionTraceability: CompanySectionTraceabilityModel;
  transportMeans: Array<TransportMeanModel>;
  licenseValid: boolean;
  licenseValidTo: string;
  createdAt: string;
  updatedAt: string;
}
