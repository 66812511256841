// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {

  @Input()
  public page!: number;

  @Input()
  public pageCount!: number;

  @Output()
  public pageChange: EventEmitter<number>;

  constructor() {
    this.pageChange = new EventEmitter();
  }

  public get allPages(): Array<number> {
    return Array(this.pageCount).fill(1).map((item, index) => index + 1);
  }

  public selectPage(page: number): void {
    this.page = page;
    this.pageChange.emit(this.page);
  }

  public previousPage(): void {
    this.selectPage(Math.max(1, --this.page));
  }

  public nextPage(): void {
    this.selectPage(Math.min(this.pageCount, ++this.page));
  }
}
