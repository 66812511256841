// Angular
import { Directive, HostListener, Output } from '@angular/core';
// External
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Directive({
  selector: '[searchInput]'
})
export class SearchInputDirective {

  @Output()
  public queryChange: Subject<string>;

  private searching: BehaviorSubject<string>;

  constructor() {
    this.queryChange = new Subject();

    this.searching = new BehaviorSubject('');
    this.searching.pipe(debounceTime(250), distinctUntilChanged()).subscribe(this.queryChange);
  }

  @HostListener('keydown.enter', ['$event'])
  public keydownHandler(event: KeyboardEvent): void {
    this.searching.next((event.target as HTMLInputElement).value);
  }

  @HostListener('input', ['$event'])
  public inputHandler(event: KeyboardEvent): void {
    this.searching.next((event.target as HTMLInputElement).value);
  }
}
