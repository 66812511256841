// Angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styles: [':host { display: block; }']
})
export class TooltipComponent {

  @Input()
  public title: string;

  @Input()
  public position: 'top' | 'bottom' | string;

  constructor() {
    this.title = '';
    this.position = 'bottom';
  }
}
