<div class="relative">
  <input [id]="id" [(ngModel)]="value" [ngModelOptions]="{ standalone: true }" dateFormat class="form-control pr-12" [class.invalid]="invalid" [placeholder]="placeholder" inputmode="decimal" pattern="^[0-9]{2}.[0-9]{2}.[0-9]{4}$" type="text" (keydown.enter)="submitHandler($event)" (focus)="focusHandler()" (blur)="blurHandler($event)">
  <label [for]="id" class="absolute z-10 top-1 right-1 block p-3 text-gray-1100 rounded-full transition-colors hover:bg-gray-200 focus:outline-none" aria-label="show calendar" tabindex="-1" role="button" (click)="showCalendar()"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.33333V2.66666M4 1.33333V2.66666" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.66663 8.16213C1.66663 5.25729 1.66663 3.80485 2.50137 2.90243C3.33612 2 4.67962 2 7.36663 2H8.63329C11.3203 2 12.6638 2 13.4986 2.90243C14.3333 3.80485 14.3333 5.25729 14.3333 8.16213V8.50453C14.3333 11.4094 14.3333 12.8618 13.4986 13.7643C12.6638 14.6667 11.3203 14.6667 8.63329 14.6667H7.36663C4.67962 14.6667 3.33612 14.6667 2.50137 13.7643C1.66663 12.8618 1.66663 11.4094 1.66663 8.50453V8.16213Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 5.33333H14" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></label>
  <div *ngIf="visibleCalendarCards" class="flex absolute z-50 bg-white rounded-lg shadow select-none" [ngClass]="{ 'bottom-full mb-2': position.includes('top'), 'top-full mt-2': position.includes('bottom'), 'left-0': position.includes('left'), 'right-0': position.includes('right') }">
    <div *ngIf="!!calendarCard" class="flex-shrink-0 w-60 space-y-2 p-2">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-center w-8 h-8 text-gray-1100 rounded-full transition-colors hover:bg-gray-200" [ngClass]="{ 'opacity-0 pointer-events-none': previousMonthIsDisabled() }" role="button" (click)="previousMonthHandler($event)"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M3.3335 8.00007L13.3335 7.99987" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.99995 4.66667L3.13802 7.5286C2.91579 7.7508 2.80469 7.86193 2.80469 8C2.80469 8.13807 2.91579 8.2492 3.13802 8.4714L5.99995 11.3333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
        <p class="font-medium text-sm text-gray-1100 capitalize">{{ (calendarCard.year + '-' + (calendarCard.month + 1)) | date:'LLLL yyyy' }}</p>
        <div class="flex items-center justify-center w-8 h-8 text-gray-1100 rounded-full transition-colors hover:bg-gray-200" [ngClass]="{ 'opacity-0 pointer-events-none': nextMonthIsDisabled() }" role="button" (click)="nextMonthHandler($event)"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M12.6665 8H2.6665" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 4.66667L12.8619 7.5286C13.0841 7.7508 13.1953 7.86193 13.1953 8C13.1953 8.13807 13.0841 8.2492 12.8619 8.4714L10 11.3333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
      </div>
      <ul class="grid grid-cols-7">
        <li *ngFor="let dayLabel of calendarCard.dayNames" class="text-xs text-gray-700 text-center capitalize">{{ dayLabel }}</li>
      </ul>
      <div class="grid grid-cols-7">
        <div *ngFor="let dayOffset of calendarCard.dayOffset">&nbsp;</div>
        <div *ngFor="let day of calendarCard.days" class="flex items-center justify-center h-8 text-center text-sm rounded-full" [ngClass]="{ 'text-white bg-green-600': isSelected(calendarCard.year, calendarCard.month, day), 'text-gray-700 pointer-events-none': isDisabled(calendarCard.year, calendarCard.month, day), 'text-gray-1100 transition-colors hover:bg-gray-200': !isSelected(calendarCard.year, calendarCard.month, day) && !isDisabled(calendarCard.year, calendarCard.month, day), 'border border-gray-300': isToday(calendarCard.year, calendarCard.month, day) && !isSelected(calendarCard.year, calendarCard.month, day) }" role="button" (click)="selectDate(calendarCard.year, calendarCard.month, day)">{{ day }}</div>
      </div>
    </div>
  </div>
</div>
