<header class="fixed z-10 top-0 left-0 w-full h-18 flex items-center justify-between py-3 pl-6 pr-3 bg-white border-b border-gray-300">
  <p class="py-2.5 font-medium text-lg text-green-600">GMP.online</p>
  <div *ngIf="staffMember | async as staffMember" dropdown #dropdown="dropdown" class="relative">
    <button class="flex items-center gap-2 p-3 text-gray-1100 rounded-lg transition hover:bg-gray-200 focus:outline-2 focus:outline-gray-1100 active:bg-gray-300" type="button"><span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="none" stroke-width="1.5"/><path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" fill="none" stroke-width="1.5" stroke-linecap="round"/></svg></span><span class="font-medium text-sm">Konto</span><span class="flex-shrink-0"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L7.5286 9.5286C7.7508 9.7508 7.86193 9.86193 8 9.86193C8.13807 9.86193 8.2492 9.7508 8.4714 9.5286L12 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span></button>
    <div *ngIf="dropdown.visible" class="absolute z-50 top-full right-0 w-80 min-w-max mt-2 bg-white divide-y divide-gray-300 rounded-2xl shadow-md">
      <div class="p-6 space-y-2">
        <p class="font-medium text-gray-1100">{{ staffMember.firstName }} {{ staffMember.lastName }}</p>
        <p class="text-sm text-gray-700">{{ staffMember.email }}</p>
      </div>
      <ng-template [modalTemplate]="'Zmień hasło'" #passwordModal="modalTemplate">
        <form #form="ngForm" action="" autocomplete="off" (ngSubmit)="changePassword(form, passwordModal)">
          <div class="space-y-2">
            <p class="font-medium text-sm text-gray-1100">Nowe hasło</p>
            <div class="relative">
              <input ngModel #passwordModel="ngModel" class="form-control pr-11" name="newPassword" placeholder="Nowe hasło" [attr.type]="passwordToggle.active ? 'text' : 'password'" required>
              <button #passwordToggle="toggle" toggle class="flex items-center justify-center absolute top-0 right-0 z-10 w-12 h-12 text-gray-700 focus:outline-none" tabindex="-1" aria-label="toggle" type="button">
                <svg *ngIf="!passwordToggle.active" class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2.3388 13.0351C2.11827 12.7317 2 12.3703 2 12C2 11.6297 2.11827 11.2683 2.3388 10.9649C3.76884 9.01625 7.28986 5 12 5C16.7101 5 20.2312 9.01625 21.6612 10.9649C21.8817 11.2683 22 11.6297 22 12C22 12.3703 21.8817 12.7317 21.6612 13.0351C20.2312 14.9837 16.7101 19 12 19C7.28986 19 3.76884 14.9837 2.3388 13.0351Z" fill="none" stroke-width="1.5"/><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="none" stroke-width="1.5"/></svg>
                <svg *ngIf="passwordToggle.active" class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2.3388 13.0351C2.11827 12.7317 2 12.3703 2 12C2 11.6297 2.11827 11.2683 2.3388 10.9649C3.76884 9.01625 7.28986 5 12 5C16.7101 5 20.2312 9.01625 21.6612 10.9649C21.8817 11.2683 22 11.6297 22 12C22 12.3703 21.8817 12.7317 21.6612 13.0351C20.2312 14.9837 16.7101 19 12 19C7.28986 19 3.76884 14.9837 2.3388 13.0351Z" fill="none" stroke-width="1.5"/><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="none" stroke-width="1.5"/><path d="M3 21L21 3" fill="none" stroke-width="1.5"/></svg>
              </button>
            </div>
            <p *ngIf="passwordModel.touched && passwordModel.hasError('required')" class="text-sm text-red-700">To pole jest wymagane</p>
          </div>
          <div class="mt-10">
            <button class="button button-solid-green button-lg w-full" type="submit"><svg *ngIf="formSending" class="w-4 h-4 fill-current animate-spin" xmlns="http://www.w3.org/2000/svg"><path d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6.006,6.006,0,0,0,8,2Z" opacity="0.4"></path><path d="M16,8H14A6.006,6.006,0,0,0,8,2V0A8.009,8.009,0,0,1,16,8Z"></path></svg><span>Ustaw hasło</span></button>
          </div>
        </form>
      </ng-template>
      <ul class="p-2">
        <li><button class="button w-full" type="button" (click)="passwordModal.present()"><span class="flex-shrink-0"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2.84504 12.5631C2.99496 13.6767 3.91726 14.549 5.03961 14.6006C5.98402 14.644 6.94337 14.6667 7.99984 14.6667C9.0563 14.6667 10.0156 14.644 10.96 14.6006C12.0824 14.549 13.0047 13.6767 13.1546 12.5631C13.2525 11.8365 13.3332 11.0917 13.3332 10.3333C13.3332 9.57493 13.2525 8.8302 13.1546 8.10353C13.0047 6.99 12.0824 6.11766 10.96 6.06606C10.0156 6.02265 9.0563 6 7.99984 6C6.94337 6 5.98402 6.02265 5.03961 6.06606C3.91726 6.11766 2.99496 6.99 2.84504 8.10353C2.7472 8.8302 2.6665 9.57493 2.6665 10.3333C2.6665 11.0917 2.7472 11.8365 2.84504 12.5631Z" fill="none" stroke-width="1.5"/><path d="M5 6V4.33333C5 2.67648 6.34315 1.33333 8 1.33333C9.3062 1.33333 10.3333 2.33333 10.6667 3.33333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.99756 10.3333H8.00356" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span><span class="flex-1 text-left">Zmień hasło</span></button></li>
        <li><button class="button w-full" type="button" (click)="logout()"><span class="flex-shrink-0"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4.68221 3.66667C3.06551 4.74159 2 6.57969 2 8.66667C2 11.9804 4.68629 14.6667 8 14.6667C11.3137 14.6667 14 11.9804 14 8.66667C14 6.57969 12.9345 4.74159 11.3178 3.66667" fill="none" stroke-width="1.5" stroke-linejoin="round"/><path d="M8 1.33333V6.66667" fill="none" stroke-width="1.5" stroke-linejoin="round"/></svg></span><span class="flex-1 text-left">Wyloguj</span></button></li>
      </ul>
    </div>
  </div>
</header>
<nav *ngIf="showNav" class="group/nav fixed z-10 top-18 left-0 bottom-0 w-18 py-4 px-3 bg-white border-r border-gray-300 overflow-hidden hover:w-72">
  <ul class="space-y-2">
    <li>
      <a [routerLink]="['companies']" [routerLinkActive]="['!text-gray-1100', '!bg-green-200']" class="flex items-center gap-3 p-3 text-gray-700 rounded-lg transition-colors hover:text-gray-1100 focus:outline-2 focus:outline-offset-4 focus:outline-gray-1100 focus:text-gray-1100">
        <span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2 22H22" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M18 9H14C11.518 9 11 9.518 11 12V22H21V12C21 9.518 20.482 9 18 9Z" fill="none" stroke-width="1.5" stroke-linejoin="round"/><path d="M15 22H3V5C3 2.518 3.518 2 6 2H12C14.482 2 15 2.518 15 5V9" fill="none" stroke-width="1.5" stroke-linejoin="round"/><path d="M3 6H6M3 10H6M3 14H6" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M15 13H17M15 16H17" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M16 22V19" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span>
        <span class="hidden font-medium text-sm whitespace-nowrap group-hover/nav:inline">Klienci</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['procedures']" [routerLinkActive]="['!text-gray-1100', '!bg-green-200']" class="flex items-center gap-3 p-3 text-gray-700 rounded-lg transition-colors hover:text-gray-1100 focus:outline-2 focus:outline-offset-4 focus:outline-gray-1100 focus:text-gray-1100">
        <span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 10C3.5 6.22876 3.5 4.34315 4.7448 3.17157C5.98959 2 7.99306 2 12 2H12.7727C16.0339 2 17.6645 2 18.7969 2.79784C19.1214 3.02643 19.4094 3.29752 19.6523 3.60289C20.5 4.66867 20.5 6.20336 20.5 9.27273V11.8182C20.5 14.7814 20.5 16.2629 20.0311 17.4462C19.2772 19.3486 17.6829 20.8491 15.6616 21.5586C14.4044 22 12.8302 22 9.68182 22C7.88275 22 6.98322 22 6.26478 21.7478C5.10979 21.3424 4.19875 20.4849 3.76796 19.3979C3.5 18.7217 3.5 17.8751 3.5 16.1818V10Z" fill="none" stroke-width="1.5" stroke-linejoin="round"/><path d="M20.5 12C20.5 13.8409 19.0076 15.3333 17.1667 15.3333C16.5009 15.3333 15.716 15.2167 15.0686 15.3901C14.4935 15.5442 14.0442 15.9935 13.8901 16.5686C13.7167 17.216 13.8333 18.0009 13.8333 18.6667C13.8333 20.5076 12.3409 22 10.5 22" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 7H15" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 11H11" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span>
        <span class="hidden font-medium text-sm whitespace-nowrap group-hover/nav:inline">Szablony procedur</span>
      </a>
    </li>
    <!--li>
      <a [routerLink]="['reports']" [routerLinkActive]="['!text-gray-1100', '!bg-green-200']" class="flex items-center gap-3 p-3 text-gray-700 rounded-lg transition-colors hover:text-gray-1100 focus:outline-2 focus:outline-offset-4 focus:outline-gray-1100 focus:text-gray-1100">
        <span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7 17V13" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M12 17V7" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M17 17V11" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" fill="none" stroke-width="1.5" stroke-linejoin="round"/></svg></span>
        <span class="hidden font-medium text-sm whitespace-nowrap group-hover/nav:inline">Raporty</span>
      </a>
    </li-->
    <li>
      <a [routerLink]="['accounts']" [routerLinkActive]="['!text-gray-1100', '!bg-green-200']" class="flex items-center gap-3 p-3 text-gray-700 rounded-lg transition-colors hover:text-gray-1100 focus:outline-2 focus:outline-offset-4 focus:outline-gray-1100 focus:text-gray-1100">
        <span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.5045 19.0002H18.9457C19.9805 19.0002 20.8036 18.5287 21.5427 17.8694C23.4202 16.1944 19.0067 14.5 17.5 14.5" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 5.06877C15.2271 5.02373 15.4629 5 15.7048 5C17.5247 5 19 6.34315 19 8C19 9.65685 17.5247 11 15.7048 11C15.4629 11 15.2271 10.9763 15 10.9312" fill="none" stroke-width="1.5" stroke-linecap="round"/><path d="M4.78256 15.1112C3.68218 15.743 0.797062 17.0331 2.55429 18.6474C3.41269 19.436 4.36872 20 5.57068 20H12.4293C13.6313 20 14.5873 19.436 15.4457 18.6474C17.2029 17.0331 14.3178 15.743 13.2174 15.1112C10.6371 13.6296 7.36292 13.6296 4.78256 15.1112Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z" fill="none" stroke-width="1.5"/></svg></span>
        <span class="hidden font-medium text-sm whitespace-nowrap group-hover/nav:inline">Użytkownicy</span>
      </a>
    </li>
  </ul>
</nav>
<main class="mt-18" [ngClass]="{ 'ml-18': showNav }">
  <router-outlet></router-outlet>
</main>
