// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  private readonly units: Array<string>;

  constructor() {
    this.units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  }

  public transform(bytes: number): string {
    let i = 0;

    for (i; bytes > 1024; i++) {
      bytes /= 1024;
    }

    return `${Math.round((bytes + Number.EPSILON) * 100) / 100} ${this.units[i]}`;
  }
}
