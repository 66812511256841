// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { CompanyAccountModel, CompanyModel, CompanyProcedureModel } from '../../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getCompany(companyId: string): Observable<CompanyModel> {
    return this.httpClient.get<CompanyModel>(`${this.apiUrl}/app-customer/v1/companies/${companyId}`);
  }

  // company account
  public getCompanyAccount(companyAccountId: string): Observable<CompanyAccountModel> {
    return this.httpClient.get<CompanyAccountModel>(`${this.apiUrl}/app-customer/v1/companyAccounts/${companyAccountId}`);
  }

  public changePassword(companyAccountId: string, newPassword: string): Observable<CompanyAccountModel> {
    return this.httpClient.post<CompanyAccountModel>(`${this.apiUrl}/app-customer/v1/companyAccounts/${companyAccountId}/password`, { newPassword });
  }

  public requestResetPassword(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-customer/v1/companyAccounts/requestResetPassword`, { email });
  }

  public resetPassword(resetToken: string, password: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-customer/v1/companyAccounts/resetPassword/${resetToken}`, { password });
  }

  // company procedure
  public getProcedures(year: string): Observable<Array<CompanyProcedureModel>> {
    return this.httpClient.get<Array<CompanyProcedureModel>>(`${this.apiUrl}/app-customer/v1/companyProcedures/byYear/${year}`);
  }

  public downloadProcedures(year: string): Observable<{ url: string; }> {
    return this.httpClient.get<{ url: string; }>(`${this.apiUrl}/app-customer/v1/companyProcedures/byYear/${year}/download`);
  }

  public getProcedure(companyProcedureId: string): Observable<CompanyProcedureModel> {
    return this.httpClient.get<CompanyProcedureModel>(`${this.apiUrl}/app-customer/v1/companyProcedures/${companyProcedureId}`);
  }

  public renderCompanyProcedureHTML(companyProcedureId: string): Observable<string> {
    return this.httpClient.get(`${this.apiUrl}/app-customer/v1/companyProcedures/${companyProcedureId}/render/html`, { responseType: 'text' });
  }

  public renderCompanyProcedurePDF(companyProcedureId: string): Observable<{ url: string; }> {
    return this.httpClient.get<{ url: string; }>(`${this.apiUrl}/app-customer/v1/companyProcedures/${companyProcedureId}/render/pdf`);
  }
}
