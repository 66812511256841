// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { RegionModel } from '../../models';
// Services
import { HelperService } from '../helper.service';
// External
import { Observable } from 'rxjs';

@Injectable()
export class LocalService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getPolishCounties(regionName: string = ''): Observable<Array<RegionModel>> {
    const params = HelperService.GetHttpParams({ regionName });
    return this.httpClient.get<Array<RegionModel>>(`${this.apiUrl}/app-auditor/v1/local/pl/counties`, { params });
  }

  public getPolishRegions(): Observable<Array<RegionModel>> {
    return this.httpClient.get<Array<RegionModel>>(`${this.apiUrl}/app-auditor/v1/local/pl/regions`);
  }
}
