// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Models
import { RegionModel } from '../../models';

@Pipe({
  name: 'countiesFilter'
})
export class CountiesFilterPipe implements PipeTransform {

  constructor() {
  }

  public transform(counties: Array<RegionModel>, regionName: string): Array<RegionModel> {
    return counties.filter(({ region }) => region === regionName);
  }
}
