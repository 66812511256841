// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { ApiDataModel, ProofDataRecordModel } from '../../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class ProofDataService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public createProofDataRecord(companyId: string, branchId: string): Observable<ProofDataRecordModel> {
    return this.httpClient.post<ProofDataRecordModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/year/2024/proofData/branch/${branchId}`, null);
  }

  public getProofDataRecord(companyId: string, proofDataRecordId: string): Observable<ProofDataRecordModel> {
    return this.httpClient.get<ProofDataRecordModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/year/2024/proofData/records/${proofDataRecordId}`);
  }

  public editProofDataRecord(companyId: string, proofDataRecordId: string, data: ApiDataModel): Observable<ProofDataRecordModel> {
    return this.httpClient.post<ProofDataRecordModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/year/2024/proofData/records/${proofDataRecordId}`, data);
  }

  public getProofDataRecords(companyId: string): Observable<Array<ProofDataRecordModel>> {
    return this.httpClient.get<Array<ProofDataRecordModel>>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/year/2024/proofData/records`);
  }

  public deleteProofDataRecord(proofDataRecordId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/proofData/${proofDataRecordId}`);
  }
}
