// Angular
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[dropdown]',
  exportAs: 'dropdown'
})
export class DropdownDirective {

  @Input()
  public visible: boolean;

  @Output()
  public visibleChange: EventEmitter<boolean>;

  private readonly element: HTMLElement;

  constructor(elementRef: ElementRef<HTMLElement>) {
    this.visible = false;
    this.visibleChange = new EventEmitter();

    this.element = elementRef.nativeElement;
  }

  @HostListener('window:click', ['$event'])
  public clickHandler(event: MouseEvent): void {
    if (!this.element.contains((event.target as HTMLElement))) {
      this.hideDropdown();
    } else {
      this.toggleDropdown();
    }
  }

  @HostListener('window:keydown.escape')
  public hideDropdown(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  public toggleDropdown(): void {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }
}
