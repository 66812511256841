// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Models
import { StaffRoleLabels } from '../../models';

@Pipe({
  name: 'staffRole'
})
export class StaffRolePipe implements PipeTransform {

  constructor() {
  }

  public transform(role: string): string {
    return StaffRoleLabels[role] || role;
  }
}
