// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { ApiDataModel, TransportMeanModel } from '../../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class TransportMeanService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getTransportMeans(companyId: string): Observable<Array<TransportMeanModel>> {
    return this.httpClient.get<Array<TransportMeanModel>>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/transportMeans`);
  }

  public createTransportMean(companyId: string, data: ApiDataModel): Observable<TransportMeanModel> {
    return this.httpClient.post<TransportMeanModel>(`${this.apiUrl}/app-auditor/v1/companies/${companyId}/transportMeans`, data);
  }

  public getTransportMean(transportMeanId: string): Observable<TransportMeanModel> {
    return this.httpClient.get<TransportMeanModel>(`${this.apiUrl}/app-auditor/v1/transportMeans/${transportMeanId}`);
  }

  public editTransportMean(transportMeanId: string, data: ApiDataModel): Observable<TransportMeanModel> {
    return this.httpClient.post<TransportMeanModel>(`${this.apiUrl}/app-auditor/v1/transportMeans/${transportMeanId}`, data);
  }

  public deleteTransportMean(transportMeanId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-auditor/v1/transportMeans/${transportMeanId}`);
  }
}
