// Angular
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Guards
import { AuditorGuard } from './guards/auditor.guard';
// Interceptors
import { AuditorInterceptor } from './interceptors/auditor.interceptor';
// Layouts
import { BaseLayoutComponent } from './layouts/base-layout';
// Components
import { AppComponent } from './app.component';
// Routing
import { AppRoutingModule } from './app-routing.module';
// Data
import { DataModule } from '@data/data.module';
// Environments
import { environment } from '@env/environment';
// Libraries
import { CoreModule } from '@libs/core/core.module';
import { FormModule } from '@libs/form/form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

registerLocaleData(localePl, 'pl');

const LocaleProvider = {
  provide: LOCALE_ID,
  useValue: 'pl'
};

const AuditorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuditorInterceptor,
  multi: true
};

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataModule.forRoot(environment.apiUrl),
    CoreModule.forRoot(),
    FormModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    BaseLayoutComponent,
    AppComponent
  ],
  providers: [
    LocaleProvider,
    AuditorInterceptorProvider,
    AuditorGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  constructor() {
  }
}
