// Angular
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: 'input[sameValueValid][ngModel], input[sameValueValid][formControl], input[sameValueValid][formControlName]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SameValueValidDirective, multi: true }
  ]
})
export class SameValueValidDirective implements Validator {

  @Input('sameValueValid')
  public sameValue!: string;

  constructor() {
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }

    return this.sameValue === control.value.toString() ? null : { sameValue: true };
  }
}
