// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
// Model
import { AuthModel } from '../../models';
// External
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  public auth: BehaviorSubject<AuthModel | null>;

  private refreshingAuthToken: boolean;

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
    this.auth = new BehaviorSubject<AuthModel | null>(null);
    this.refreshingAuthToken = false;
  }

  public getAuthorizationRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (!!this.auth.value && !!this.auth.value.token && !request.url.includes(`${this.apiUrl}/app-auditor/v1/jwt`)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.value.token}`
        }
      });
    }

    return request;
  }

  public authorizationHandler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshingAuthToken && !request.url.includes(this.apiUrl)) {
      return this.refreshHandler(request, next);
    }

    return next.handle(this.getAuthorizationRequest(request));
  }

  public refreshHandler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authRefreshToken: Observable<any> = this.refresh();

    return authRefreshToken.pipe(
      switchMap(() => next.handle(this.getAuthorizationRequest(request)))
    );
  }

  public login(username: string, password: string): Observable<AuthModel> {
    return this.httpClient.post<AuthModel>(`${this.apiUrl}/app-auditor/v1/jwt`, { username, password });
  }

  public refresh(): Observable<AuthModel | null> {
    if (this.refreshingAuthToken) {
      return this.auth.pipe(
        first(auth => {
          return !!auth && !!auth.refreshToken;
        }),
        finalize(() => {
          this.refreshingAuthToken = false;
        })
      );
    }

    if (!!this.auth.value) {
      const { refreshToken } = JSON.parse(JSON.stringify(this.auth.value));

      this.refreshingAuthToken = true;
      this.auth.next({ ...this.auth.value, refreshToken: '' });

      return this.httpClient.post<AuthModel>(`${this.apiUrl}/app-auditor/v1/jwt/refresh`, { refreshToken }).pipe(
        tap(auth => {
          this.auth.next({ ...this.auth.value, ...auth });
        }),
        finalize(() => {
          this.refreshingAuthToken = false;
        })
      );
    }

    return of(null);
  }
}
