// Angular
import { formatDate, FormStyle, getLocaleDayNames, TranslationWidth } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, LOCALE_ID } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
// Models
import { CalendarCard, diffDays, isToday } from '../../models';

let datePickerId = 0;

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styles: [':host { display: block; }']
})
export class DatepickerComponent implements ControlValueAccessor {

  @Input()
  public exclusionDates: Array<Date>;

  @Input()
  public position: string;

  @Input()
  public placeholder: string;

  @Input()
  public showOnFocus: boolean;

  @Input()
  public minDate?: Date;

  @Input()
  public maxDate?: Date;

  public id: string;

  public value: string | undefined;
  public date: Date | undefined;

  public calendarCard: CalendarCard | undefined;

  public visibleCalendarCards: boolean;

  private changeHandler!: (value: string | null) => void;
  private touchedHandler!: () => void;

  constructor(private elementRef: ElementRef<HTMLElement>, @Inject(LOCALE_ID) private locale: string, private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;

    this.exclusionDates = [];
    this.position = 'left bottom';
    this.placeholder = 'DD.MM.RRRR';
    this.showOnFocus = false;

    this.id = `datepicker-${++datePickerId}`;
    this.visibleCalendarCards = false;
  }

  public get invalid(): boolean | null {
    return this.ngControl.invalid && this.ngControl.touched;
  }

  public writeValue(value: string): void {
    const valueValid = /^\d{4}-\d{2}-\d{2}$/.test(value || '');
    const [year, month, date] = (value || '').split('-');

    if (valueValid && !this.isDisabled(+year, +month - 1, +date)) {
      this.date = new Date(+year, +month - 1, +date);
      this.value = formatDate(this.date, 'dd.MM.yyyy', this.locale);
    }

    if (!valueValid) {
      this.value = undefined;
      this.date = undefined;
    }
  }

  public registerOnChange(callback: (value: string | null) => void): void {
    this.changeHandler = callback;
  }

  public registerOnTouched(callback: () => void): void {
    this.touchedHandler = callback;
  }

  public submitHandler(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const value = this.value || '';
    const valueValid = /^\d{2}.\d{2}.\d{4}$/.test(value);
    const [date, month, year] = value.split('.');

    if (valueValid && !this.isDisabled(+year, +month - 1, +date)) {
      this.date = new Date(+year, +month - 1, +date);
      this.value = formatDate(this.date, 'dd.MM.yyyy', this.locale);

      this.changeHandler(formatDate(this.date, 'yyyy-MM-dd', this.locale));

      if (this.visibleCalendarCards) {
        this.getSelectedCalendarCards();
      }
    }

    if (!valueValid) {
      this.value = undefined;
      this.date = undefined;

      this.changeHandler('');
      this.hideCalendar();
    }
  }

  public focusHandler(): void {
    if (this.showOnFocus) {
      this.showCalendar();
    }
  }

  public blurHandler(event: FocusEvent): void {
    const value = this.value || '';
    const valueValid = /^\d{2}.\d{2}.\d{4}$/.test(value);
    const [date, month, year] = value.split('.');
    const formattedDate = !!this.date ? formatDate(this.date, 'dd.MM.yyyy', this.locale) : '';

    if (value !== formattedDate && !valueValid) {
      this.value = formattedDate;
    }

    if (value !== formattedDate && valueValid && !this.isDisabled(+year, +month - 1, +date)) {
      this.date = new Date(+year, +month - 1, +date);
      this.value = formatDate(this.date, 'dd.MM.yyyy', this.locale);
      this.changeHandler(formatDate(this.date, 'yyyy-MM-dd', this.locale));
    }

    if (!!event.relatedTarget) {
      this.hideCalendar();
    }

    this.touchedHandler();
  }

  public showCalendar(): void {
    this.getSelectedCalendarCards();
    this.visibleCalendarCards = true;
  }

  public hideCalendar(): void {
    this.visibleCalendarCards = false;
  }

  @HostListener('window:mousedown', ['$event'])
  public outsideClickHandler(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains((event.target as HTMLElement))) {
      this.hideCalendar();
    }
  }

  public getSelectedCalendarCards(): void {
    const calendarStartDate = this.date || this.minDate || new Date();
    this.calendarCard = this.getCalendarCard(calendarStartDate.getFullYear(), calendarStartDate.getMonth());
  }

  public getCalendarCard(year: number, month: number): CalendarCard {
    const firstWeekDay = 1;
    const firstDay = new Date(year, month);
    const lastDay = new Date(year, month + 1, 0);

    const offset = firstDay.getDay() - firstWeekDay;
    const dayOffset = offset < 0 ? Array(7 + offset) : Array(offset);
    const localeDayNames = getLocaleDayNames(this.locale, FormStyle.Standalone, TranslationWidth.Short);
    const dayNames = [...JSON.parse(JSON.stringify(localeDayNames)).slice(firstWeekDay), ...JSON.parse(JSON.stringify(localeDayNames)).splice(0, firstWeekDay)];

    const days = [];

    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(i);
    }

    return { year, month, days, dayNames, dayOffset };
  }

  public selectDate(year: number, month: number, date: number): void {
    this.date = new Date(year, month, date);
    this.value = formatDate(this.date, 'dd.MM.yyyy', this.locale);

    this.changeHandler(formatDate(this.date, 'yyyy-MM-dd', this.locale));
    this.hideCalendar();
  }

  public previousMonthHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.previousMonth();
  }

  public nextMonthHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.nextMonth();
  }

  public previousMonth(): void {
    let { year, month } = this.calendarCard!;

    if (--month < 0) {
      month = 11;
      year--;
    }

    this.calendarCard = this.getCalendarCard(year, month);
  }

  public nextMonth(): void {
    let { year, month } = this.calendarCard!;

    if (++month > 11) {
      month = 0;
      year++;
    }

    this.calendarCard = this.getCalendarCard(year, month);
  }

  public previousMonthIsDisabled(): boolean {
    const { year, month } = this.calendarCard!;
    return !!this.minDate && year === this.minDate.getFullYear() && month === this.minDate.getMonth();
  }

  public nextMonthIsDisabled(): boolean {
    const { year, month } = this.calendarCard!;
    return !!this.maxDate && year === this.maxDate.getFullYear() && month === this.maxDate.getMonth();
  }

  public isToday(year: number, month: number, date: number): boolean {
    return isToday(year, month, date);
  }

  public isSelected(year: number, month: number, date: number): boolean {
    return !!this.date && diffDays(this.date, new Date(year, month, date)) === 0;
  }

  public isDisabled(year: number, month: number, date: number): boolean {
    const disabledDate = new Date(year, month, date);
    const lowerMinDate = !!this.minDate && diffDays(this.minDate, disabledDate) < 0;
    const greaterMaxDate = !!this.maxDate && diffDays(this.maxDate, disabledDate) > 0;
    const includeExclusionDates = this.exclusionDates.some(exclusionDate => exclusionDate.getFullYear() === year && exclusionDate.getMonth() === month && exclusionDate.getDate() === date);

    return lowerMinDate || greaterMaxDate || includeExclusionDates;
  }
}
