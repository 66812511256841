// Angular
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
// Models
import { ApiDataModel } from '@data/models';

@Injectable()
export class HelperService {

  constructor() {
  }

  public static GetFormData(data: ApiDataModel): FormData {
    const formData = new FormData();

    const appendFormData = (formData: FormData, key: string, value: string | number | boolean | null | Array<string | number | boolean | ApiDataModel>) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (typeof item === 'object') {
            Object.entries(item).forEach(([itemKey, itemValue]) => {
              appendFormData(formData, `${key}[][${itemKey}]`, itemValue);
            });
          } else {
            formData.append(`${key}[]`, `${item}`);
          }
        });
      } else if (value === true) {
        formData.append(key, '1');
      } else if (value === false) {
        formData.append(key, '0');
      } else {
        formData.append(key, `${value}`);
      }
    };

    Object.entries(data).forEach(([key, value]) => {
      appendFormData(formData, key, value);
    });

    return formData;
  }

  public static GetHttpParams(data: ApiDataModel): HttpParams {
    let params = new HttpParams();

    const appendParams = (params: HttpParams, key: string, value: string | number | boolean | null | Array<string | number | boolean | ApiDataModel>): HttpParams => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (typeof item === 'object') {
            Object.entries(item).filter(([, value]) => {
              return value !== undefined && value !== null;
            }).forEach(([itemKey, itemValue]) => {
              params = appendParams(params, `${key}[][${itemKey}]`, itemValue);
            });
          } else {
            params = params.append(`${key}[]`, `${item}`);
          }
        });
      } else if (value === true) {
        params = params.append(key, '1');
      } else if (value === false) {
        params = params.append(key, '0');
      } else {
        params = params.append(key, `${value}`);
      }

      return params;
    };

    Object.entries(data).filter(([, value]) => {
      return value !== undefined && value !== null;
    }).forEach(([key, value]) => {
      params = appendParams(params, key, value);
    });

    return params;
  }
}
