// Angular
import { Component, Input, Optional } from '@angular/core';
// Models
import { NotificationModel, Overlay } from '../../models';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent {

  @Input()
  public notifications!: Array<NotificationModel>;

  constructor(@Optional() private overlay: Overlay<NotificationsComponent>) {
  }

  public closeNotification(notificationIndex: number): void {
    this.notifications.splice(notificationIndex, 1);

    if (!!this.overlay && !this.notifications.length) {
      this.overlay.dismiss();
    }
  }
}
