// Angular
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
// External
import Editor from '@editor/build/ckeditor';

@Component({
  selector: 'editor',
  exportAs: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {

  @ViewChild('editor')
  public editorRef!: ElementRef<HTMLElement>;

  @ViewChild('toolbar')
  public toolbarRef!: ElementRef<HTMLElement>;

  public editor!: Editor;

  public value: string;

  public disabled: boolean;

  constructor(private ngControl: NgControl, private ngZone: NgZone) {
    this.ngControl.valueAccessor = this;
    this.value = '';
    this.disabled = false;
  }

  public ngAfterViewInit(): void {
    const editorElement = document.createElement('div');
    this.editorRef.nativeElement.append(editorElement);

    Editor.create(editorElement, {
      placeholder: 'Wpisz treść…',
      typing: {
        transformations: {
          include: []
        }
      }
    }).then(editor => {
      this.editor = editor;

      if (!!this.editor) {
        this.editor.setData(this.value);
      }

      if (this.disabled) {
        this.editor.enableReadOnlyMode('LOCKED');
      }

      this.editor.model.document.on('change:data', () => {
        this.ngZone.run(() => {
          this.changeHandler(editor.getData());
        });
      });

      this.editor.editing.view.document.on('blur', () => {
        this.ngZone.run(() => {
          this.touchedHandler();
        });
      });
    });
  }

  public async ngOnDestroy(): Promise<void> {
    await this.editor.destroy();
  }

  public get invalid(): boolean | null {
    return this.ngControl.invalid && this.ngControl.touched;
  }

  public getData(): string {
    return this.editor.getData();
  }

  public changeHandler(value: string | null): void {
  }

  public touchedHandler(): void {
  }

  public writeValue(value: string | null): void {
    this.value = value || '';

    if (!!this.editor) {
      this.editor.setData(this.value);
    }
  }

  public registerOnChange(callback: any): void {
    this.changeHandler = callback;
  }

  public registerOnTouched(callback: any): void {
    this.touchedHandler = callback;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;

    if (!!this.editor) {
      if (disabled) {
        this.editor.enableReadOnlyMode('LOCKED');
      } else {
        this.editor.disableReadOnlyMode('LOCKED');
      }
    }
  }
}
