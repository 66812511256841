// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// Pipes
import { CountiesFilterPipe } from './pipes/counties-filter';
import { FileAsyncPipe } from './pipes/file-async';
import { StaffRolePipe } from './pipes/staff-role';
// Services
import { AuthService as AuthServiceForAuditor } from '@data/services/auditor/auth.service';
import { CompanyService as CompanyServiceForAuditor } from '@data/services/auditor/company.service';
import { FileService as FileServiceForAuditor } from '@data/services/auditor/file.service';
import { LocalService as LocalServiceForAuditor } from '@data/services/auditor/local.service';
import { ProcedureService as ProcedureServiceForAuditor } from '@data/services/auditor/procedure.service';
import { ProofDataService as ProofDataServiceForAuditor } from '@data/services/auditor/proof-data.service';
import { ReportDataService as ReportDataServiceForAuditor } from '@data/services/auditor/report-data.service';
import { StaffService as StaffServiceForAuditor } from '@data/services/auditor/staff.service';
import { TransportMeanService as TransportMeanServiceForAuditor } from '@data/services/auditor/transport-mean.service';

import { AuthService as AuthServiceForCustomer } from '@data/services/customer/auth.service';
import { CompanyService as CompanyServiceForCustomer } from '@data/services/customer/company.service';

import { HelperService } from './services/helper.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  declarations: [
    CountiesFilterPipe,
    FileAsyncPipe,
    StaffRolePipe
  ],
  exports: [
    CountiesFilterPipe,
    FileAsyncPipe,
    StaffRolePipe
  ]
})
export class DataModule {

  constructor() {
  }

  public static forRoot(apiUrl: string): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        { provide: 'API_URL', useValue: apiUrl },
        AuthServiceForAuditor,
        CompanyServiceForAuditor,
        FileServiceForAuditor,
        LocalServiceForAuditor,
        ProcedureServiceForAuditor,
        ProofDataServiceForAuditor,
        ReportDataServiceForAuditor,
        StaffServiceForAuditor,
        TransportMeanServiceForAuditor,
        AuthServiceForCustomer,
        CompanyServiceForCustomer,
        HelperService
      ]
    };
  }
}
