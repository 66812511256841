// Angular
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
// Models
import { StaffModel } from '@data/models';
// Directives
import { ModalTemplateDirective } from '@libs/core/directives/modal-template';
// Guards
import { AuditorGuard } from '@app/auditor/guards/auditor.guard';
// Services
import { AuthService } from '@data/services/auditor/auth.service';
import { StaffService } from '@data/services/auditor/staff.service';
import { NotificationService } from '@libs/core/services/notification.service';
// External
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent {

  public staffMember: Observable<StaffModel | null>;
  public showNav: boolean;
  public formSending: boolean;

  constructor(private router: Router,
              private auditorGuard: AuditorGuard,
              private authService: AuthService,
              private staffService: StaffService,
              private notificationService: NotificationService) {
    this.staffMember = this.auditorGuard.staffMember;
    this.showNav = !!this.auditorGuard.staffMember.value && this.auditorGuard.staffMember.value.role === 'ROLE_ADMIN';
    this.formSending = false;
  }

  public changePassword(form: NgForm, modal: ModalTemplateDirective): void {
    form.control.markAllAsTouched();

    if (form.invalid || this.formSending) {
      return;
    }

    const { staffId } = this.authService.auth.value!;
    const { newPassword } = form.value;

    this.formSending = true;
    this.staffService.changeStaffMemberPassword(staffId, newPassword).pipe(
      finalize(() => {
        this.formSending = false;
      })
    ).subscribe({
      next: () => {
        this.notificationService.showSuccess('Zmiany zapisano pomyślnie.');
        modal.dismiss();
      },
      error: () => {
        this.notificationService.showDanger('Wystąpił błąd podczas zapisywania danych.');
      }
    });
  }

  public async logout(): Promise<void> {
    await this.router.navigate(['auth', 'login']);
    this.auditorGuard.staffMember.next(null);

    window.localStorage.removeItem('auth');
    this.authService.auth.next(null);
  }
}
