// Angular
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[passwordGenerate]'
})
export class PasswordGenerateDirective {

  @Output()
  public change: EventEmitter<string>;

  constructor() {
    this.change = new EventEmitter();
  }

  private generatePassword(passwordLength: number): string {
    const numberChars = '0123456789';
    const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    const allChars = numberChars + upperChars + lowerChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3).map(x => x[Math.floor(Math.random() * x.length)]);

    for (let i = randPasswordArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = randPasswordArray[i];
      randPasswordArray[i] = randPasswordArray[j];
      randPasswordArray[j] = temp;
    }

    return randPasswordArray.join('');
  }

  @HostListener('click')
  public clickHandler(): void {
    this.change.emit(this.generatePassword(8));
  }
}
