// Angular
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
// Models
import { NotificationType } from '../../models';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit, OnDestroy {

  @Input()
  public type!: NotificationType;

  @Input()
  public icon!: NotificationType;

  @Input()
  public message?: string;

  @Input()
  public canClose?: boolean;

  @Input()
  public duration?: number;

  @Output()
  public close: EventEmitter<void>;

  public progress?: number;

  private startTime?: number;
  private currentTime?: number;

  private animationFrame?: number;

  constructor() {
    this.close = new EventEmitter();
  }

  ngOnInit(): void {
    if (!!this.duration) {
      this.startTime = (new Date()).getTime();
      this.animationProgress();
    }
  }

  ngOnDestroy(): void {
    if (!!this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
  }

  private animationProgress(): void {
    if (!this.startTime || !this.duration) {
      return;
    }

    this.currentTime = (new Date()).getTime();
    this.progress = Math.round(Math.min(1, (this.currentTime - this.startTime) / this.duration) * 100);

    if (this.progress < 100) {
      this.animationFrame = window.requestAnimationFrame(() => {
        this.animationProgress();
      });

      return;
    }

    this.close.emit();
  }

  public closeHandler(): void {
    this.close.emit();
  }
}
