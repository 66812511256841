// Angular
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[stopPropagation]'
})
export class StopPropagationDirective {

  constructor() {
  }

  @HostListener('click', ['$event'])
  public clickHandler(event: MouseEvent) {
    event.stopPropagation();
  }
}
